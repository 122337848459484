<template>
  <b-overlay
      :show="users === null"
      rounded="sm"
  >

    <div v-if="users !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

<!--            <b-col-->
<!--                cols="12"-->
<!--                md="6"-->
<!--                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--            >-->
<!--              <label>نمایش</label>-->
<!--              <v-select-->
<!--                  v-model="perPage"-->
<!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                  :options="perPageOptions"-->
<!--                  :clearable="false"-->
<!--                  class="per-page-selector d-inline-block mx-50"-->
<!--              />-->
<!--              <label>تعداد</label>-->
<!--            </b-col>-->

            <!--            &lt;!&ndash; Search &ndash;&gt;-->
            <!--            <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--            >-->
            <!--              <div class="d-flex align-items-center justify-content-end">-->
            <!--                <b-form-input-->
            <!--                    v-model="searchCommand"-->
            <!--                    class="d-inline-block mr-1"-->
            <!--                    placeholder="جستجو..."-->
            <!--                />-->
            <!--              </div>-->
            <!--            </b-col>-->
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="users"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی یافت نشد."
        >

          <template #cell(userInfo.userId)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/media/gallery/profile/${data.item.userInfo.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.userInfo.name+' '+data.item.userInfo.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userInfo.userId }}</small>
            </b-media>
          </template>

          <template #cell(userCoupon.createDate)="data">
            <small class="">
              {{data.item.userCoupon.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.userCoupon.createDate.slice(0,10))}}
            </small>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
<!--            <b-col-->
<!--                cols="12"-->
<!--                sm="6"-->
<!--                class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
<!--            >-->

<!--              <b-pagination-->
<!--                  v-model="currentPage"-->
<!--                  :total-rows="totalCounts"-->
<!--                  :per-page="perPage"-->
<!--                  first-number-->
<!--                  last-number-->
<!--                  class="mb-0 mt-1 mt-sm-0"-->
<!--                  prev-class="prev-item"-->
<!--                  next-class="next-item"-->
<!--              >-->
<!--                <template #prev-text>-->
<!--                  <feather-icon style="transform: rotate(180deg)"-->
<!--                                icon="ChevronLeftIcon"-->
<!--                                size="18"-->
<!--                  />-->
<!--                </template>-->
<!--                <template #next-text>-->
<!--                  <feather-icon-->
<!--                      icon="ChevronRightIcon"-->
<!--                      size="18"-->
<!--                  />-->
<!--                </template>-->
<!--              </b-pagination>-->

<!--            </b-col>-->

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import {CouponGetUserByCouponIdRequest} from "@/libs/Api/Coupon";

export default {
  name: "CouponUsed",
  title:"مصرف کنندگان کوپن - پنل ادمین مکس ",
  data(){
    return{
      users:null,
      couponId:this.$route.params.id,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'userInfo.userId' ,label :'اطلاعات کاربر'},
        { key: 'userInfo.nationalId' ,label :'کد ملی'},
        { key: 'userInfo.mobile' ,label :'موبایل'},
        { key: 'userCoupon.createDate' ,label :'تاریخ و زمان استفاده'},
      ],
      searchCommand:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getUsers()
  },
  methods:{
    refreshData(){
      this.getStudents(this.perPage,this.currentPage,this.productId)
    },
    async getUsers(){
      let _this = this;

      let couponGetUserByCouponIdRequest = new CouponGetUserByCouponIdRequest(_this);
      couponGetUserByCouponIdRequest.setId(_this.couponId);
      await couponGetUserByCouponIdRequest.fetch(function (content){
        _this.users = content;
        console.log(content)
      },function (error){
        console.log(error);
      })
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getStudents(nv,this.currentPage,this.productId);
    },
    currentPage:function (nv,ov) {
      this.getStudents(this.perPage,nv,this.productId);
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>